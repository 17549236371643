.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #d42e12;
}

.ant-badge-status-processing {
  background-color: rgb(85 153 255);
}

.ant-badge-status-processing::after {
  border: 1px solid rgb(68 122 255);
}

.btn-abst {
  position: absolute !important;
  z-index: 1;
  right: 1.3rem;
  top: 3rem;
}

.inv-select {
  height: auto;
  font-size: 12px;
}

.inv-select .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  /* white-space: nowrap; */
  white-space: normal;
  word-break: break-all;
}

.sticky-card {
  position: sticky !important;
  z-index: 8;
  top: 12.3rem;
}

.fs-12 .ant-table table,
.fs-12 {
  font-size: 12px;
}

.ant-form-item-control-input {
  min-height: auto;
}

.ant-input-disabled,
.ant-input-number-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-input > input-disabled,
.ant-picker-input > input[disabled] {
  color: #54636d !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #54636d !important;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  word-break: break-word;
}

.poi-info-window .full-width {
  color: #455560;
}

.poi-info-window a:link {
  color: #d42e12;
}

.collapse-panel-custom-map-header .ant-collapse-header {
  font-size: 21px;
  font-weight: 500;
}

.collapse-panel-custom-map-header .ant-collapse-header .ant-collapse-extra {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.custom-collapse-stock-opname .ant-collapse-item .ant-collapse-header .ant-collapse-extra {
  float: none;
}

.collapse-panel-custom-map-header .ant-collapse-content .ant-collapse-content-box {
  padding: 0;
}

.button-custom span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-card-custom .ant-card-body {
  padding-bottom: 5px;
}

.card-data-stockist .ant-card-body {
  padding-bottom: 8px;
}

.collapse-panel-custom .ant-collapse-header {
  font-size: 18px;
  font-weight: 600;
  color: #54636d !important;
}

.collapse-panel-custom .ant-collapse-header .ant-collapse-extra {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
  color: #54636d !important;
}

.badge-box-custom .ant-badge-status-dot {
  border-radius: 20%;
}

.salesapp-draft-panel .ant-collapse-content-box {
  padding: 0px;
}

.apexcharts-legend .apexcharts-align-center .apx-legend-position-right {
  margin-top: -10vh;
  display: flex;
  justify-content: center;
}

.import-margin-stockist {
  display: flex;
  flex-wrap: wrap;
}

.import-margin-stockist .ant-transfer-operation {
  padding: 20px 0;
  margin: 0;
}
.collapse-panel-p0 .ant-collapse-header {
  padding: 0px !important;
}

.collapse-panel-p0 .ant-collapse-content-box {
  padding: 0px 0px 0px 0px !important;
}

.w500 {
  width: 47%;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.custom-collapse-product-focus .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding-top: 0;
  padding-bottom: 0;
}

.mt10 {
  margin-top: 10px;
}

.borderless-datepicker {
  border: 0px !important;
  padding: 0px !important;

  &.ant-picker-focused {
    box-shadow: white 0px 0px 0px 2px !important;
    &.ant-picker:hover {
      border-right-width: 0px !important;
    }
  }
}

#inputBordlessDatePicker {
  cursor: pointer;
  text-align: right;
  color: #d42e12;
}
